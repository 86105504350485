import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import { Button, Offcanvas } from "react-bootstrap";
import { useState } from "react";

const CustomFilters = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const clearFilters = () => {
    // alert("ss");
    // Reset filter values here
    props.setFilterFrom("");
    props.setFilterTo("");
    // props.setFilterSortValue(""); // Assuming you have a function to set filter sort value
    // Call function to fetch all records
    props.listData(); // Replace this with the actual function to fetch all records
    handleClose(); // Close the filter panel after clearing filters
  };


  
  return (
    <>
      <Button
        variant="primary"
        className="primery-color filter-btn"
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faFilter} />
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="off-convas"
      >
        <Offcanvas.Header>
          <h2 className="mainTitle text-white mb-0">Filters</h2>
          <span className="close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes}  />
          </span>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="tableFilters">
            <div className="row">
              <div className="col-12 ">
                {props?.dateFilter && (
                  <div className="filterWrapper">
                    <label className="filterLabel mb-4">Sort by date:</label>
                    <div className="row mb-4">
                      <div className="col-6">
                        <label className="filterLabel">From:</label>
                        <input
                          type="date"
                          placeholder="From"
                          name="from"
                          className="filterInput w-100"
                          defaultValue={props?.filterFrom}
                          onChange={(event) => {
                            props?.setFilterFrom(event.target.value);
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <label className="filterLabel">To:</label>
                        <input
                          type="date"
                          placeholder="To"
                          name="to"
                          className="filterInput w-100"
                          defaultValue={props?.filterTo}
                          onChange={(event) => {
                            props?.setFilterTo(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {props?.filterSortValues?.length > 1 ? (
        <div className="col-12 mb-4">
                {props?.filterSort && (
                  <div className="filterWrapper">
                    <label className="filterLabel">Show by status:</label>
                    <select
                      className="filterInput w-100"
                      value={props?.filterSortValue}
                      onChange={(event) => {
                        props?.setFilterSortValue(event.target.value);
                      }}
                    >
                      {props?.filterSortValues.map((item) => (
                        <option key={item.value} value={item.value}>
                          Sort by {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
      ) : (
        ''
      )}
              
            </div>
            <div>
              <button type="button" className="off-convas-btn bg-primery mb-3 w-100" onClick={() => {
                  props.searchFilter();
                }}>
                Apply
              </button>
              <button type="button" className="modelbutton greybutton hover-grey w-100" onClick={clearFilters}>
                Cancel
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomFilters;
