import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

import { feedbacks } from "./../../Config/Data";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";

import axios from 'axios';

const FeedbackDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate()

  const [data, setData] = useState({});
  const [feedbackData, setFeedbackData] = useState({});

  const fetchData = async () => {
    let response = await axios
      .get(`admin/feedback/${id}`)
      .catch((e) => {
        console.log(e.response.data.message);
      });
    if (response.status) {
      console.log(response.data.data)
      setData(response.data.data);
    }
  };

  useEffect(() => {
    document.title = 'Mournify | Feedback Details';

    fetchData();
    
  }, []);

  return (
    <>
      <DashboardLayout>
      <div className="row my-4">
            <div className="col-12">
              <h2 className="mainTitle">
                <BackButton />
                User Feedback
              </h2>
            </div>
          </div>
        <div className="dashCard mb-4">
          <div className="row">
          {data.name ?
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-8 col-xl-8 col-xxl-6">
                  <div className="col-12">
                    <div className=" mb-2">
                      <h6 className="primaryColor fw-bold"> Full Name </h6>
                      <p className="secondaryText">{data?.name}</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className=" mb-2">
                      <h6 className="primaryColor fw-bold"> Email </h6>
                      <p className="secondaryText">{data?.email}</p>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-2">
                      <h6 className="primaryColor fw-bold">Subject </h6>
                      <p className="secondaryText">{data?.subject}</p>
                    </div>

                  </div>
                  <div className="col-12">
                    <div className="mb-2">
                      <h6 className="primaryColor fw-bold">Date</h6>
                      <p className="secondaryText">{data?.created_at}</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-2">
                      <h6 className="primaryColor fw-bold">Message</h6>
                      <p className="secondaryText">
                      {data?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          : ''}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default FeedbackDetails;
