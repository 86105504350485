import React, { useEffect, useState } from "react";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    subject: "",
  });

  useEffect(() => {
    document.title = "Mournify | contact us";
  }, []);

  return (
    <section className="contact-us pt-5">
      <div class="container">
        <div className="row py-3 text-center">
          <div className="col-12">
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <div class="row py-3">
              <div className="col-md-4">
                <CustomInput
                  label="Name"
                  required
                  id="name"
                  type="name"
                  placeholder="Enter Name"
                  labelClass="mainLabel"
                  inputClass="mainInput border-radius-none"
                  onChange={(event) => {
                    setFormData({ ...formData, name: event.target.value });
                  }}
                />
              </div>
              <div className="col-md-4">
                <CustomInput
                  label="Email"
                  required
                  id="userEmail"
                  type="email"
                  placeholder="Enter Email Address"
                  labelClass="mainLabel"
                  inputClass="mainInput border-radius-none"
                  onChange={(event) => {
                    setFormData({ ...formData, email: event.target.value });
                  }}
                />
              </div>
              <div className="col-md-4">
                <CustomInput
                  label="subject"
                  required
                  id="subject"
                  type="subject"
                  placeholder="Enter subject "
                  labelClass="mainLabel"
                  inputClass="mainInput border-radius-none"
                  onChange={(event) => {
                    setFormData({ ...formData, subject: event.target.value });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="" className="mainLabel">
                  Message <span className="text-danger">*</span>
                </label>
                <textarea
                  onChange={(event) => {
                    setFormData({ ...formData, subject: event.target.value });
                  }}
                  className="mainInput border-radius-none "
                  name=""
                  placeholder="Enter Message..."
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <CustomButton
                  type="submit"
                  variant="primaryButton"
                  text="Submit"
                  onClick={() => {
                    alert("submit");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
