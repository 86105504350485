import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { headerlogo, userImage } from '../../Assets/images'
// import Notifications from "../../Screens/Notifications";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUser,
  faBars,
  faEllipsisV,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";

import CustomModal from '../../Components/CustomModal';
import { useNavigate } from "react-router-dom";
import { notifications } from "../../Config/Data";

import { useNotificationContext } from "../../Layout/Header/NotificationContext";
import "./style.css";
import axios from "axios";

export const Header = (props) => {
  const { notificationCount, updateNotificationCount } = useNotificationContext(); // Access context values

const navigate = useNavigate()

  const [notificationState, setNotificationState] = useState([])
  const [mynotificationCount, setNotificationCount] = useState([])

  const [showModal, setShowModal] = useState(false);
  const Userdata = JSON.parse(localStorage.getItem("user"));
  
  const fetchNotifications = async () => {
    let response = await axios
      .get(`admin/notification/unread/list`)
      .catch((e) => {
        console.log(e.response.data.message);
      });
    if (response.status) {
      console.log(response.data.data)
      setNotificationState(response.data.data)
      
    }
  };

  const getNotificationCount = async () => {
    let response = await axios
      .get(`admin/unread/notification/count`)
      .catch((e) => {
        console.log(e.response.data.message);
      });
    if (response.status) {
      console.log(response.data.count)
      setNotificationCount(response.data.count)
      updateNotificationCount(response.data.count);
      // localStorage.setItem('noti_count',notificationCount);
    }
  };

  useEffect(() => {
    // setNotificationState(notifications)
    fetchNotifications()
    getNotificationCount()

  }, [])
  
  const handleClick = () => {
    setShowModal(true)
}

const modalAction = () => {  
    setShowModal(false)
    localStorage.clear();
    navigate('/login')
}

// const updateNotificationCount = async () => {
//   await getNotificationCount();
// };

  return (
    <header>
      <Navbar className="customHeader" expand="md">
        <Container fluid>
          <Link to={"/dashboard"} className="siteLogo order-2 order-lg-1">
            <img src={headerlogo} alt="Logo" />
          </Link>
          <Navbar.Toggle className="order-4 order-lg-2 notButton">
            <FontAwesomeIcon className="bell-icon text-white" icon={faEllipsisV} />
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="customCollapse order-3"
          >
            <Nav className="ms-auto">
              <Dropdown className="notiDropdown me-2 ">
                <Dropdown.Toggle variant="transparent pb-0" className="notButton notifi-btn">
                  <FontAwesomeIcon className="bellIcon" icon={faBell} />
                  <span className="badge">{notificationCount}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="notiMenu" align="end">
                  <div className="notificationsBody">
                    <div className="d-flex justify-content-between align-items-baseline pt-3 singleNoti notify-border">
                      <h6 className="fw-bold ">Notifications</h6>
                      <p className="notify-bg">{notificationCount} New</p>
                    </div>
                    {notificationState.slice(0, 5).map((notification) => (
                      <>
                        <Link className="singleNoti" key={notification.id}>
                          {/* <div className="singleNotiIcon">
                          <img src={notification.avatar} alt="user" className="img-fluid single-notifi-img"/>
                          </div> */}
                          <div className="singleNotiContent">
                          <p className="notiText">{notification.title}</p>
                            <p className="notiText">{notification.message}</p>
                            <p className="notiDateTime">
                              {notification.created_at}
                            </p>
                          </div>
                        </Link>
                      </>
                    ))}
                  </div>
                  <div className="notiFooter">
                  <Link to={"/notifications"}>View All
                  </Link>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="userDropdown">
                <Dropdown.Toggle
                  variant="transparent"
                  className="notButton toggleButton text-white"
                >
                  <div className="userImage">
                    <img
                      src={Userdata?.avatar_url}
                      alt="User"
                      style={{ borderRadius: '50%' }}
                      className="img-fluid"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="userMenu" align="end">
                  <Link className="userMenuItem" to={'/profile'}>
                    <FontAwesomeIcon
                      className="me-2 yellow-text"
                      icon={faUser}
                    />{" "}
                    Profile
                  </Link>
                  <Link className="userMenuItem" onClick={() => {handleClick()} } >
                    <FontAwesomeIcon
                      className="me-1 yellow-text"
                      icon={faSignOut}
                    />{" "}
                    Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
          <button className="notButton ms-md-2 order-lg-4 order-md-4 order-1 text-white">
            <FontAwesomeIcon
              className="bell-icon"
              onClick={props.sidebarToggle}
              icon={faBars}
            />
          </button>
        </Container>
      </Navbar>
      <CustomModal show={showModal} close={() => { setShowModal(false) }} action={modalAction}  heading='Are you sure you want to logout?' />
    </header>
    
  );
};
