import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowUpLong
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const StatsCard = (props) => {
  return (
    <>
      <div className="statsCard px-xl-3 px-0">
        <div className="statsContent">
          <div className="statsImg">
            <img src={props.image} alt="Card Icon" />
          </div>
          <div className="statsData">
            <h3 className="statsNumber">{props.total}</h3>
            <p className="statsText p-0 m-0">{props.text}</p>
          </div>
        </div>
        <div className="statsChange">
          <p className="m-0 p-0 fw-bold">
            {props.increase ? (
              <FontAwesomeIcon
                icon={faArrowUpLong}
                className="me-2 greenColor arrow-icon"
              />
            ) : (
              <FontAwesomeIcon
                icon={faArrowCircleDown}
                className="me-2 redColor"
              />
            )}
            {props.trends}%
          </p>
          <p className="statsText">{props.text2}</p>
        </div>
      </div>
    </>
  );
};

export default StatsCard;
