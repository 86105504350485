import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";

import { AuthLayout } from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";

const ForgetPassword2 = (props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [codes, setCodes] = useState(["", "", "", ""]);
  useEffect(() => {
    document.title = "mounrify | Password Recovery";
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    formData.email = props.email;
    formData.token = [...codes].join('');
    let response = await axios.post("admin/verify/code", formData).then((response) => {
      // console.log(response.data.errors);

      if (response.data.errors) {
        document.getElementById(
          "responseFromServer"
        ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${response.data.message}</div>`;

        setTimeout(() => {
          document.getElementById("responseFromServer").children[0].hidden = true;
        }, 1500);

      }
      else {

        document.getElementById(
          "responseFromServer"
        ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Success !</strong> ${response.data.message}</div>`;

        setTimeout(() => {
          document.getElementById("responseFromServer").hidden = true;
          navigate("/forget-password3");
        }, 1500);
      }

      // document.getElementById(
      //   "responseFromServer"
      // ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Success !</strong> ${response.data.message}</div>`;

      // setTimeout(() => {
      //   document.getElementById("responseFromServer").hidden = true;
      //   navigate("/forget-password3");
      // }, 1500);

    }).catch((e) => {

      document.getElementById(
        "responseFromServer"
      ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.message}</div>`;

      setTimeout(() => {
        document.getElementById("responseFromServer").children[0].hidden = true;
      }, 1500);
    });


  };


  const handleCodeChange = (e, index) => {
    const { value } = e.target;
    const newCodes = [...codes];
    newCodes[index] = value;
    setCodes(newCodes);

    if (value && index < 3) {
      const nextInput = document.querySelector(`.code-${index + 1}`);
      nextInput.focus();
    } else if (!value && index > 0) {
      const prevInput = document.querySelector(`.code-${index - 1}`);
      prevInput.focus();
    }

    console.log(document.getElementsByClassName('code-1'));
  };
  async function handleResendClick(e) {
    e.preventDefault();
    let response = await axios.post("admin/reset/password", { email: props.email }).then((response) => {

      
      if (response.data.status == true) {
        document.getElementById(
        "responseFromServer"
      ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Success !</strong> ${response.data.msg}</div>`;
      
     
      }
      else{
        document.getElementById(
          "responseFromServer"
        ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> Something went wrong!</div>`;
        // ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.msg}</div>`;
        setTimeout(() => {
          document.getElementById("responseFromServer").children[0].hidden = true;
          navigate("/forget-password");
        }, 1500);
      }
      
    }).catch((e) => {
         
      document.getElementById(
        "responseFromServer"
      ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> Something went wrong!</div>`;
      // ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.msg}</div>`;
      setTimeout(() => {
        document.getElementById("responseFromServer").children[0].hidden = true;
        navigate("/forget-password");
      }, 1500);
     });

 

  }
  return (
    <>
      <AuthLayout
        authTitle="Verification Code"
        authPara="Please check your email for verification code. Your code is 4 digit in length."
        backOption={true}
      >
        <div id="responseFromServer"></div>
        <form onSubmit={handleClick}>
          <label
            className="fw-bold text-uppercase fw-exbold  d-block mb-3"
            htmlFor=""
          >
            Verification Code
            <span className="text-danger">*</span>
          </label>
          <div className="form-field code-container">
            {codes.map((code, index) => (
              <input
                key={index}
                className={`code code-${index}`}
                type="text"
                placeholder="0"
                pattern="[0-9]"
                maxLength="1"
                required
                value={code}
                onChange={(e) => handleCodeChange(e, index)}

              />
            ))}
          </div>
          <div className="text-end">
            <button className="resend-btn" onClick={handleResendClick}>Resend Code</button>
          </div>
          <div className="mt-4 text-center">
            <CustomButton
              type="submit"
              variant="primaryButton"
              text="Continue"
            />
          </div>
        </form>
      </AuthLayout>
    </>
  );
};

export default ForgetPassword2;
