import ForgetPassword2 from "../Screens/Auth/ForgetPassword2";
import { connect } from "react-redux";
import emailTraveling from "../Services/Actions/action";

const mapStateToProps = (state) => state.emailTraveling;
const mapDispatchToProps = (dispatch) => ({
  emailHandler: (data) => dispatch(emailTraveling),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword2);
