import constants from "../constants";

const emailTraveling = (data) => {
  return {
    type: constants.EMAIL_TRAVELING,
    data: data,
  };
};

export default emailTraveling;
