import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import { currentUser } from "./../../Config/Data";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import CustomModal from '../../Components/CustomModal';

import './style.css'


import axios from "axios";

const ChangePassword = () => {

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()

    const [userData, setUserData] = useState({});

    useEffect(() => {

        document.title = 'Mournify | Change Password';

    }, []);
   

    const handleClick = () => {
        setShowModal(true)
    }

    const submitForm = async (e) => {
        e.preventDefault();
        console.log(userData);
        if (
          !userData.old_password ||
          !userData.password ||
          !userData.password_confirmation
        ) {
          document.getElementById(
            "resultDiv"
          ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                            <strong>Oops!</strong> Please fill the form correctly.
                          </div>`;
          setTimeout(() => {
            document.getElementById("result").hidden = true;
          }, 2000);
        } else if (userData.password != userData.password_confirmation) {
          document.getElementById(
            "resultDiv"
          ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                            <strong>Oops!</strong> Password not matched.
                          </div>`;
          setTimeout(() => {
            document.getElementById("result").hidden = true;
          }, 2000);
        } else {
          let response = await axios
            .post("admin/update-password", userData)
            .then((response) => {

              console.log(response);
              if (response.data.status == true) {

                  document.getElementById(
                "resultDiv"
              ).innerHTML = `<div class="alert alert-success" id="result" role="alert">
                              <strong>Success!</strong> ${response.data.data.message}.
                            </div>`;
      
              setTimeout(() => {
                document.getElementById("result").hidden = true;
                navigate("/profile");
              }, 3000);
                
              }
              else{
                document.getElementById(
                  "resultDiv"
                ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                              <strong>Oops!</strong> ${response.data.errors?.old_password ?? response.data.errors?.old_password }.
                            </div>`;
      
                setTimeout(() => {
                  document.getElementById("result").hidden = true;
                }, 3000);
              }
            

              
            }).catch((e) => {
        
              document.getElementById(
                "resultDiv"
              ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                            <strong>Oops!</strong> ${e.response.data.errors?.old_password ?? e.response.data.errors?.old_password }.
                          </div>`;
    
              setTimeout(() => {
                document.getElementById("result").hidden = true;
              }, 3000);

          });

        }
      };

    const modalAction = () => {
        setShowModal(false)
        navigate('/login')
    }

    return (
        <>
            <DashboardLayout>
            <div className="row my-4">
                        <div className="col-12">
                            <h2 className="mainTitle">
                                <BackButton />
                                Change Password
                            </h2>
                        </div>
                    </div>
                <div className="dashCard mb-4">
                    <div className="row mb-3">
                        <div className="col-md-12" id="resultDiv"></div>
                        <div className="col-lg-5 col-md-6">
                            <form onSubmit={submitForm}>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <CustomInput 
                                            label="Current Password" 
                                            name="old_password" 
                                            labelClass="mainLabel" 
                                            required 
                                            type="password" 
                                            placeholder="Enter Current Password" 
                                            inputClass="mainInput"
                                            onChange={(event) => {
                                                setUserData({
                                                  ...userData,
                                                  old_password: event.target.value,
                                                });
                                              }}  
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <CustomInput 
                                            label="New Password" 
                                            name="password" 
                                            labelClass="mainLabel" 
                                            required 
                                            type="password" 
                                            placeholder="Enter New Password" 
                                            inputClass="mainInput" 
                                            onChange={(event) => {
                                                setUserData({
                                                  ...userData,
                                                  password: event.target.value,
                                                });
                                              }}  
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <CustomInput 
                                            label="Confirm New Password" 
                                            name="password_confirmation" 
                                            labelClass="mainLabel" 
                                            required 
                                            type="password" 
                                            placeholder="Confirm New Password" 
                                            inputClass="mainInput" 
                                            onChange={(event) => {
                                                setUserData({
                                                  ...userData,
                                                  password_confirmation: event.target.value,
                                                });
                                              }}  
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12r">
                                        <CustomButton type="submit" variant="primaryButton" className="me-3 mb-2" text="Update"  />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            <CustomModal show={showModal} close={() => { setShowModal(false) }} successAction={modalAction} success heading='Password Updated Successfully!' />

        </>
    );
};

export default ChangePassword;
