import "./style.css";
import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faHeart, faFaceSadTear } from "@fortawesome/free-solid-svg-icons";
import { userImage } from "../../Assets/images";

const LikeModel = (props) => {

  const [key, setKey] = useState("home");



  const [reactionData, setPostReaction] = useState([]);

  const [PostId, setPostId] = useState(props.linkPostId)
  // 
  useEffect(() => {
    console.log('new state');
    if (props.likePostId !== PostId) {
      setPostId(props.likePostId);
      fetchData(props.likePostId);
    }
  }, [props.likePostId]);

  const fetchData = async () => {
    let response = await axios
      .get(`admin/get-likes/${props.likePostId}`)
      .catch((e) => {
        // console.log(e.response.data.message);
      });
    if (response.status) {

      setPostReaction(response.data.data.reactions)


    }
  };

  // useEffect(() => {

  //   console.log(props.likePostId);
  //   console.log('comment modal');  



  //   if(props.likePostId !== undefined) {
  //     fetchData();    
  //   }

  // }, [])



  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header closeButton className=" bg-transparent">
          <Modal.Title>Reaction List</Modal.Title>
        </Modal.Header>
        <Modal.Body className="like-model">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="home" title={"All " + reactionData.length}>
              {Array.isArray(reactionData) && reactionData.length === 0 && (
                <p>No records.</p>
              )}

              {Array.isArray(reactionData) && reactionData.length > 0 && (
                reactionData.map((item, index) => (

                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="img-border">
                        <img src={item.user.avatar_url} className="round_img" alt="User" />
                      </div>
                      <div>
                        <h6 className="sub-title m-0">{item.user.name}</h6>
                      </div>
                    </div>
                    <span>
                      {" "}

                      {item.type == "like" ? (
                        <FontAwesomeIcon icon={faFaceSadTear} className="yellow" />
                      ) : item.type == "heart" ? (
                        <FontAwesomeIcon
                          icon={faHeart}
                          className=" redColor"
                        />
                      ) : (

                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className=" primaryColor"
                        />
                      )}
                    </span>

                  </div>

                ))
              )}

            </Tab>
            
            <Tab
              eventKey="profile"
              title={
                <span>
                  {" "}
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    className=" primaryColor"
                  />{" "}
                  {reactionData.filter(item => item.type === "like").length}
                </span>
              }
            >

              

              
              



              {Array.isArray(reactionData) && reactionData.length === 0 && (
                <p>No records.</p>
              )}

              {Array.isArray(reactionData) && reactionData.length > 0 && (
                reactionData
                  .filter(item => item.type === "like")
                  .map((item, index) => (
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="img-border">
                          <img src={item.user.avatar_url} className="round_img" alt="User" />
                        </div>
                        <div>
                          <h6 className="sub-title m-0">{item.user.name}</h6>
                        </div>
                      </div>
                      <span>
                        {" "}
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className=" primaryColor"
                        />
                      </span>
                    </div>

                  ))
              )}



            </Tab>
            <Tab
              eventKey="contact"
              title={
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faFaceSadTear} className="yellow" /> {reactionData.filter(item => item.type === "sad").length}
                </span>
              }
            >


              {Array.isArray(reactionData) && reactionData.length === 0 && (
                <p>No records.</p>
              )}

              {Array.isArray(reactionData) && reactionData.length > 0 && (
                reactionData
                  .filter(item => item.type === "sad")
                  .map((item, index) => (
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="img-border">
                          <img src={item.user.avatar_url} className="round_img" alt="User" />
                        </div>
                        <div>
                          <h6 className="sub-title m-0">{item.user.name}</h6>
                        </div>
                      </div>
                      <span>
                        {" "}
                        <FontAwesomeIcon icon={faFaceSadTear} className="yellow" />
                      </span>
                    </div>

                  ))
              )}


            </Tab>
            <Tab
              eventKey="heardReactions"
              title={
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faHeart} className="redColor" /> {reactionData.filter(item => item.type === "heart").length}
                </span>
              }
            >


              {Array.isArray(reactionData) && reactionData.length === 0 && (
                <p>No records.</p>
              )}

              {Array.isArray(reactionData) && reactionData.length > 0 && (
                reactionData
                  .filter(item => item.type === "heart")
                  .map((item, index) => (
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="img-border">
                          <img src={item.user.avatar_url} className="round_img" alt="User" />
                        </div>
                        <div>
                          <h6 className="sub-title m-0">{item.user.name}</h6>
                        </div>
                      </div>
                      <span>
                        {" "}
                        <FontAwesomeIcon icon={faHeart} className="redColor" />
                      </span>
                    </div>

                  ))
              )}


            </Tab>

          </Tabs>
          {/* <div className="d-flex justify-content-between align-items-center py-2">
                <button className=" readButton notButton primaryColor">View All Reactions</button>
              </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LikeModel;
