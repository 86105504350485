import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

// import { Dropdown } from "react-bootstrap";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import CustomTable from "../../Components/CustomTable";
import CustomModal from "../../Components/CustomModal";

import UseTableControls from "../../Config/UseTableControls";


import { UserData } from "../../Config/Data";

import "./style.css";
import axios from "axios";

const UserManagement = () => {

  const {
    filterSort,
    filterSortValue,
    setFilterSortValue,
    paginateFilter,
    setPaginateFilter,
    filterSortValues,
    filterSearch,
    filterSearchValue,
    setFilterSearchValue,
    dateFilter,
    filterFrom,
    setFilterFrom,
    filterTo,
    setFilterTo
  } = UseTableControls();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const inactiveMale = () => {
    setShowModal(false)
    setShowModal2(true)
  }
  const activeMale = () => {
    setShowModal3(false)
    setShowModal4(true)
  }

  const listData = async (page = 1, filter = null) => {
    setData([]);
    let pages = page;
    let response = await axios
      .get(`admin/users?page=${pages + filter}`)
      .catch((e) => {
        console.log(e.response.data.message);
      });
    if (response.status) {
      let filter = response.data;
      window.e = filter;
      setData(response.data.data);
      setPaginateFilter(filter.meta);
    }
  };

  const searchFilter = () => {

    let search = `&search=${filterSearchValue}`;
    let queryParams = `&from=${filterFrom}&to=${filterTo}&sort=${filterSortValue}`;

    listData(undefined, search + queryParams);
  };


  const pageChange = (value = 1) => {
    listData(value);
  }
  useEffect(() => {
    document.title = 'Mournify | User Management';
    listData();
  }, []);

  const userHeaders = [
    {
      key: "id",
      title: "S.No",
    },

    {
      key: "username",
      title: "FullName",
    },
    {
      key: "email",
      title: "Email Address",
    },
    {
      key: "registered",
      title: "Registered On",
    },
    {
      key: "status",
      title: "Status",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  const sortingValues = [
    {
      value: '',
      text: 'All'
    },
    {
      value: 0,
      text: 'Inactive'
    },
    {
      value: 1,
      text: 'Active'
    }
  ]

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12 mb-2 d-sm-flex justify-content-between">
              <h2 className="mainTitle">User Management</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="dashCard">
                <div className="row mb-3">
                  <div className="col-12">
                    <CustomTable
                      headers={userHeaders}
                      filterSort={true}
                      filterSortValue={filterSortValue}
                      setFilterSortValue={setFilterSortValue}
                      filterSortValues={sortingValues}
                      filterSearch={true}
                      filterSearchValue={filterSearchValue}
                      setFilterSearchValue={setFilterSearchValue}
                      searchFilter={searchFilter}
                      setPaginateFilter={setPaginateFilter}
                      paginateFilter={paginateFilter}
                      dateFilter={true}
                      filterFrom={filterFrom}
                      setFilterFrom={setFilterFrom}
                      filterTo={filterTo}
                      setFilterTo={setFilterTo}
                      pageChange={pageChange}
                      listData={listData}

                    >
                      <tbody>
                        {data.length === 0 ? (
                          <tr>
                            <td colSpan="6">No records found!</td>
                          </tr>
                        ) : (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.created_at}</td>
                              <td>{item.status ? 'Active' : 'Inactive'}</td>
                              <td>
                                <Link to={`/user-management/details/${item.id}`} className="tableAction">
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))
                        )}

                      </tbody>
                    </CustomTable>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CustomModal show={showModal} close={() => { setShowModal(false) }} action={inactiveMale} heading='Are you sure you want to mark this user as inactive?' />
          <CustomModal show={showModal2} close={() => { setShowModal2(false) }} success heading='Marked as Inactive' />

          <CustomModal show={showModal3} close={() => { setShowModal3(false) }} action={activeMale} heading='Are you sure you want to mark this user as Active?' />
          <CustomModal show={showModal4} close={() => { setShowModal4(false) }} success heading='Marked as Active' />



        </div>
      </DashboardLayout>
    </>
  );
};


export default UserManagement