import constants from "../constants";

const initiate = {
  email: null,
};
export default function emailTraveling(state = initiate, action) {
  switch (action.type) {
    case constants.EMAIL_TRAVELING:
    
      return {
        ...state,
        email: action.data,
      };
      break;
    default:
      return state;
      break;
  }
}
