import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { currentUser } from "./../../Config/Data";
import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomInput from "../../Components/CustomInput";
import CustomButton from "../../Components/CustomButton";
import './style.css'

import axios from "axios";

const EditProfile = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [userNewData, setUserNewData] = useState({});

    useEffect(() => {
        document.title = 'Mournify | Edit Profile';

        let user = JSON.parse(localStorage.getItem("user"));
        setUserData(user);        
        setUserNewData(user);
    }, []);

    
  const submitForm = async (e) => {
    e.preventDefault();
    userNewData.avatar = document.getElementById("profileImage").files[0];
    if (!userNewData.name) {
      document.getElementById(
        "resultDiv"
      ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                        <strong>Oops!</strong> Please fill the form correctly.
                      </div>`;
      setTimeout(() => {
        document.getElementById("result").hidden = true;
      }, 2000);
    } else {
          await axios.post("admin/edit-profile", userNewData, {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        })
        .then((response) => {
          if(response.data.status == true){
            userNewData.avatar_url = response.data.data.avatar_url;
            localStorage.setItem("user", JSON.stringify(userNewData));
            document.getElementById(
              "resultDiv"
            ).innerHTML = `<div class="alert alert-success" id="result" role="alert">
                            <strong>Success!</strong> ${response.data.message}.
                          </div>`;
    
            setTimeout(() => {
              document.getElementById("result").hidden = true;
              navigate("/profile");
            }, 2000);
          }
          else{

            document.getElementById(
              "resultDiv"
            ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                          <strong>Oops!</strong> ${response.data.message}.
                        </div>`;
  
            setTimeout(() => {
              document.getElementById("result").hidden = true;
            }, 2000);

          }
         
         
        }).catch((e) => {
          document.getElementById(
            "resultDiv"
          ).innerHTML = `<div class="alert alert-danger" id="result" role="alert">
                        <strong>Oops!</strong> ${e.response.data.message}.
                      </div>`;

          setTimeout(() => {
            document.getElementById("result").hidden = true;
          }, 2000);
        
        });
    
    }
  };

    const handleUpdate = () => {
        currentUser.name = userNewData.name || userData.name;
        currentUser.avatar_url = userNewData.avatar_url || userData.avatar_url;
        navigate('/profile');
    };

    return (
        <DashboardLayout>
            <div className="row my-4">
                <div className="col-12">
                    <h2 className="mainTitle">
                        <BackButton />Edit Profile
                    </h2>
                </div>
            </div>
            <div className="dashCard mb-4">
                {userData && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <form onSubmit={submitForm}>
                                <div className="row mb-3">
                                    <div className="col-lg-4 my-2">
                                        <div className="profileImage">
                                            <div className="overlay-profile-img"></div>
                                            <img src={userNewData.avatar_url || userData.avatar_url} alt="User" />
                                            <input type="file" accept="img/*" className="d-none" id="profileImage" onChange={(event) => setUserNewData({ ...userNewData, avatar_url: URL.createObjectURL(event.target.files[0]) })} />
                                            <label htmlFor="profileImage" className="imageUploadButton"><FontAwesomeIcon icon={faCamera} /></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 col-lg-6 col-xl-5">
                                        <CustomInput label="First Name" labelClass="mainLabel" value={userNewData.name || userData.name} required type="text" placeholder="Enter First Name" inputClass="mainInput" onChange={(event) => setUserNewData({ ...userNewData, name: event.target.value })} />
                                        <CustomInput label="Last Name" labelClass="mainLabel" value={userNewData.last_name || userData.last_name} required type="text" placeholder="Enter Last Name" inputClass="mainInput" onChange={(event) => setUserNewData({ ...userNewData, last_name: event.target.value })} />
                                        <div className="col-12 mb-3">
                                            <h4 className="secondaryLabel primaryColor">Email Address</h4>
                                            <p className="secondaryText">{userData.email}</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <CustomButton type="submit" variant="primaryButton" className="me-3 mb-2" text="Update"/>
                                    </div>
                                    <div className="col-12" id="resultDiv"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </DashboardLayout>
    );
};

export default EditProfile;