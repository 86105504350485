import { connect } from "react-redux";
import ForgetPassword from "../Screens/Auth/ForgetPassword";
import emailTraveling from "../Services/Actions/action";

const mapStateToProps = (state) => ({
  data: state,
});
const mapDispatchToProps = (dispatch) => (
  {
  emailHandler: (data) => {
    
    dispatch(emailTraveling(data))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
