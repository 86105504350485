import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faMessage, faUser} from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export const Sidebar = (props) => {
  const location = useLocation();
  return (
    <div className={`sidebar ${props.sideClass}`} id="sidebar">
      <ul className="list-unstyled">
        {[
          {to: "/dashboard", icon: faBorderAll, text: "Dashboard"},
          {to: "/user-management", icon: faUser, text: "User Management"},
          {to: "/feedbacks", icon: faMessage, text: "Feedback Management"}
        ].map((item) => (
          <li key={item.to} className="sidebar-li my-3">
            <Link className={`sideLink ${location.pathname.includes(item.to) ? 'active' : ''}`} to={item.to}>
              <span className="sideIcon">
                <FontAwesomeIcon icon={item.icon} />
              </span>
              <span className="sideLinkText">{item.text}</span>
            </Link>
          </li> 
        ))}
      </ul>
    </div>
  );
};