import { React, useState } from 'react';
import CustomButton from '../CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar } from '@fortawesome/free-solid-svg-icons';
import './style.css'
import axios from 'axios';
import { useNotificationContext } from "../../Layout/Header/NotificationContext";
const NotificationCard = (props) => {
    // console.log('abc');
    // console.log('notification card'+props.notificationCount);
    const [notiRead, setNotiRead] = useState(props.read);
    const [uuid, setUuid] = useState(props.id);

    const { updateNotificationCount } = useNotificationContext();

    const markAsRead = async () => {
        let response = await axios
            .post("admin/notification/read", {uuid})
            .catch((e) => {
              console.log(e)
            });
            if (response) {
                setNotiRead(!notiRead);
                // Update the notification count using the context function
                updateNotificationCount((prevCount) => prevCount - 1);
              }
    };

    return (
        <div className={`notificationWrapper ${notiRead ? 'unread' : ''}`}>
            <div className={`d-sm-flex justify-content-between align-items-center gap-3 `} key={props.id}>
                <div className="d-flex gap-3">
                    <div className="notificationImageIcon">
                    {/* <img src={props.image} alt="user-image" className="img-fluid"/> */}
                    </div>
                    <div className="flex-grow-1">
                   
                        <p className="notificationText"><span className='text-black fw-bold'>{props.title}</span> </p>
                        <div className="dateTime">
                            <p className="p-sm text-gry mb-0"><FontAwesomeIcon icon={faClock} className="me-2" />{props?.time}</p>
                            <span className='mx-2'>|</span>
                            <p className="p-sm text-gry mb-0"><FontAwesomeIcon icon={faCalendar} className="me-2" />{props?.date}</p>
                         </div>
                    </div>
                </div>
                <div className="flex-shrink-0 text-end">
                    {notiRead === true? 
                    <CustomButton onClick={() => markAsRead() } variant={notiRead ? 'notreadButton' : 'readButton'} className="notButton" text={notiRead ? "Mark as Read" : "Mark as Unread"} />
                    : ""
                    }
                    </div>
            </div>
        </div>

    )
}

export default NotificationCard
