import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import "./style.css";


import { AuthLayout } from '../..//Layout/AuthLayout';
import CustomInput from "../../Components/CustomInput"
import CustomButton from '../../Components/CustomButton';


const ForgetPassword = (props) => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({})

  useEffect(() => {
    document.title = 'mounrify | Password Recovery';
  }, [])

  const handleClick =  async (e) => {
    e.preventDefault()
    let response = await axios.post("admin/reset/password", formData).then((response) => {
      // document.getElementById(
      //   "responseFromServer"
      // ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.message}</div>`;

      // setTimeout(() => {
      //   document.getElementById("responseFromServer").children[0].hidden = true;
      // }, 1500);

      if (response.data.errors) {
        document.getElementById(
           "responseFromServer"
         ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${response.data.message}</div>`;
   
         setTimeout(() => {
           document.getElementById("responseFromServer").children[0].hidden = true;
         }, 1500);
       }
       else {
         document.getElementById(
           "responseFromServer"
         ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Success !</strong> ${response.data.msg}</div>`;
         props.emailHandler(formData.email);
         setTimeout(() => {
           document.getElementById("responseFromServer").hidden = true;
           navigate("/forget-password2");
         }, 1000);
   
       }

    }) .catch((e) => {
      console.log(e);
     document.getElementById(
           "responseFromServer"
         ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.message}</div>`;
   
         setTimeout(() => {
           document.getElementById("responseFromServer").children[0].hidden = true;
         }, 1500);
    });

  }

  return (

    <AuthLayout authTitle='Password Recovery' authPara='Enter email address to receive a verification code.' backOption={true}>
      <div id="responseFromServer"></div>
      <form onSubmit={handleClick}>
        <CustomInput label='Email Address' required id='userEmail' type='email' placeholder='Enter Your Email Address' labelClass='mainLabel' inputClass='mainInput' onChange={(event) => {
          setFormData({ ...formData, email: event.target.value })
        }} />
        <div className="mt-4 text-center">
          <CustomButton type='submit' variant='primaryButton' text='Continue' />
        </div>
      </form>

    </AuthLayout>

  )
}



export default ForgetPassword
