import React, { useEffect } from "react";
const PrivacyAndPolicy = () => {
  useEffect(() => {
    document.title = "Mournify | Privacy Policy";
  }, []);

  return (
    <div class="container">
      <div class="row py-3">
        <div class="col-12">
          <h2 class="text-center mainTitle pb-3">Privacy Policy</h2>
          <h3 className="sub-title">Introduction</h3>
          <p>
            Mournify is a website and app that lets people all over the United
            States know about a death. This privacy policy explains how Mournify
            collects, uses, and protects your personal information when you use
            our website or app. Mournify is committed to protecting your
            privacy, and we take our duties to keep your personal information
            safe and private very seriously.
          </p>
          <h3 className="sub-title">The Information We Gather</h3>
          <p>
            When you use our website or app, Mournify gets personal information
            from you. Among these are:
          </p>

          <ul>
            <li>
              Name and contact information: When you sign up for our services,
              we get your name, email address, phone number, and mailing
              address.
            </li>
            <li>
              Payment information: We collect your credit card information if
              you choose to use our paid services.
            </li>
            <li>
              Location information: When you use our app, we collect information
              about where you are so that we can give you more accurate
              information and alerts.
            </li>
            <li>
              Information about how you use our website or app: We keep track of
              how you use our website or app, including how you interact with
              our notifications and other features.
            </li>
          </ul>
          <h3 className="sub-title">How We Use the Data You Give Us</h3>
          <p>
            We use the information you give us to improve our services and give
            you better ones. This includes: letting you know when someone dies
            in your area.
          </p>
          <ul>
            <li>
              Getting in touch with you when we make changes to our services or
              our privacy policy.
            </li>
            <li>Helping you with customer service and support.</li>
            <li>
              Getting better at what we do and making our services fit your
              needs better.
            </li>
            <li>
              We may also use your personal information for other things if you
              give us permission or if the law says we have to.
            </li>
          </ul>
          <h3 className="sub-title">Sharing What You Know</h3>

          <p>
            Mournify may give your personal information to third-party service
            providers who help us provide our services. Payment processing
            services to handle your payments are part of this.
          </p>
          <p>
            You can use email and messaging services to get alerts and other
            messages sent to you.
          </p>
          <p>
            Mapping and geolocation services to help us give more accurate
            information and alerts.
          </p>
          <p>
            We might also give out your personal information if the law says we
            have to or if we need to protect our legal rights.
          </p>
          <h3 className="sub-title">Your Information's Safety</h3>
          <p>
            Mournify cares about the security of your personal information and
            takes reasonable steps to keep it from being accessed, used, or
            shared by people who shouldn't be able to. We protect your personal
            information with security measures that are standard in the
            industry, such as encryption and secure server technology.
          </p>
          <h3 className="sub-title">Your Rights</h3>
          <p>
            You have certain rights when it comes to the information about you,
            such as the right to:
          </p>
          <ul>
            <li>Look at your own information.</li>
            <li>Fix any mistakes in the information about you.</li>
            <li>
              Ask us to get rid of the personal information we have about you.
            </li>
            <li>Don't want your personal information to be used?</li>
            <li>Limit how your personal information can be used.</li>
            <li>
              Get your personal information in a format that machines can read
              and is structured.
            </li>
            <li>
              To use these rights, please use the information below to get in
              touch with us.
            </li>
          </ul>
          <h3 className="sub-title">Distribution of data</h3>
          <p>
            Mournify may share your personal information with third-party
            service providers who help us provide the Services, such as hosting
            providers, payment processors, and customer support providers. We
            may also share your information with our affiliates and other
            Service users so that you can use the Services more easily.
          </p>
          <p>
            We may give out your information if the law says we have to or if a
            court order or subpoena tells us to. We may also give out your
            information if we think it's needed to keep people or property from
            getting hurt or to stop fraud or other illegal activities.
          </p>
          <h3 className="sub-title">Security</h3>
          <p>
            Mournify use encryption and firewalls, among other things, to keep
            your information safe from unauthorized access or use. But neither
            sending information over the internet nor storing it electronically
            is 100% safe, so we can't promise that your information will always
            be safe.
          </p>
          <h3 className="sub-title">Children</h3>
          <p>
            The Services are not meant for kids younger than 13 years old. We
            don't know of any way to get personal information from kids younger
            than 13.
          </p>
          <h3 className="sub-title">Links to Other Sites</h3>
          <p>
            There may be links to third-party websites or services on the
            Services. We are not in charge of how these third-party sites handle
            your privacy or what they have on them.
          </p>
          <h3 className="sub-title">Changes to how we handle your privacy</h3>
          <p>
            This privacy policy may be changed from time to time by Mournify. If
            we make changes to our privacy policy that are important to you, we
            will let you know by putting a notice on our website or app.
          </p>
          <h3 className="sub-title">Talk to us!</h3>
          <p>
            If you have any questions about Mournify's policy or how we handle
            your personal information, you can email us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
