import "./style.css";
import Modal from "react-bootstrap/Modal";
import { userImage } from "../../Assets/images";
import axios from "axios";
import { useState, useEffect } from "react";
const CommentsModel = (props) => {

  const [commentData, setPostComment] = useState({});

  const [getPostId, setPostId] = useState(props.postId)
// 
  useEffect(() => {
    console.log('new state');
    if (props.postId !== getPostId) {
      setPostId(props.postId);
      fetchData(props.postId);
    }
    // if(props.postId !== undefined) {
      fetchData();    
    // }
  }, [props.postId]);
  
  const fetchData = async () => {
    console.log('ssss')
    let response = await axios
      .get(`admin/get-comment/${props.postId}`)
      .catch((e) => {
        // console.log(e.response.data.message);
      });
    if (response.status) {
      console.log(response.data.data)

      setPostComment(response.data.data)
   
    }
  };

  // useEffect(() => {
    
  //   console.log(props.postId);
  //   console.log('comment modal');
  // }, [])

  // fetchData();  

  return (
    <>
      <Modal show={props.show} onHide={props.handleCloseComments} centered>
        <Modal.Header closeButton className=" bg-transparent">
          <Modal.Title>View Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Comments-model">
          <div className="pb-3 comment-border">
            <h6 className="model-title m-0">All ({commentData.length})</h6>
          </div>
        <div className="d-flex gap-3">
          <div className="img-border">
            
          </div>
          <div>
      
          </div>
        </div>   
              {Array.isArray(commentData) && commentData.length === 0 && (
  <p>No comments.</p>
  )}

  {Array.isArray(commentData) && commentData.length > 0 && (
  commentData.map((item, index) => (
    
    <div className="d-flex gap-3">
              <div className="img-border">
                <img src={item.user.avatar_url} alt="User" />
              </div>
            <div>
            <h4 className="sub-title m-0">{item.user.name}</h4>
            <p className="date-color">{item.description}</p>
          </div>
        </div>
  ))
  )}
    
            
      
        {/* <div className="d-flex justify-content-between align-items-center py-2">
                <button className=" readButton notButton primaryColor">View All Comments</button>
              </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommentsModel;
