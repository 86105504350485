import { useEffect, useState } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";



function AuthGuard(props) { 
  const [formData] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const { Component } = props;
  const locationHere = useLocation().pathname.replace(/['/']/g, "");
  const navigate = useNavigate();

  useEffect(() => {
    
  let reverseAuthGuard = [
    "login",
    "forget-password",
    "forget-password2",
    "forget-password3",
  ];
    let login = localStorage.getItem("_token");
    let user = localStorage.getItem("user");

    console.log(reverseAuthGuard.indexOf(locationHere));
    if (!login || !user) {
      setIsAuth(false)
      if (reverseAuthGuard.indexOf(locationHere) > -1) {
        console.log("data");
      } else navigate("/login");
    } else {
      setIsAuth(true)
      if (reverseAuthGuard.indexOf(locationHere) > -1) {
        // console.log(reverseAuthGuard.indexOf(location.pathname));
        navigate("/dashboard");
      }
    }
  },[]);

  return (
    <div>
      <Component />
    </div>
  );
}
export default AuthGuard;
