import { useState, useEffect } from "react";

import { DashboardLayout } from "../../Layout/DashboardLayout";
import StatCard from "../../Components/StatsCard";
import { stats } from "../../Config/Data";
import { CChart } from "@coreui/react-chartjs";

import "./style.css";

import axios from "axios";

export const Dashboard = () => {
  const [statistics, setStatistics] = useState([]);
  const [selectedValue, setSelectedValue] = useState('Y');
  const [months, setMonths] = useState([]);
  const [records, setRecords] = useState([]);

  const handleChange = async (e) => {
    const newValue = e.target.value;
      console.log(newValue);
    setSelectedValue(newValue);

    // Make Axios request based on the selected value
    try {
      const response = await axios.get(`admin/chart/filter?filter=${newValue}`);
      // Handle the response data as needed
      setMonths(response.data.months);
      setRecords(response.data.records);
      // console.log(response.data.months);
      // console.log(response.data.months);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchChartData = () => {
    axios.get('admin/chart/data').then((response)=>{
        setMonths(response.data.months);
        setRecords(response.data.records);

    }).catch((e) => { console.log(e)});
  }

  const fetchStats = () => {
    axios
      .get(`admin/dashboard/stats`).then( (response) => {
        setStatistics(response.data)
      })
      .catch((e) => {
        console.log(e);
      });    
  };

  useEffect(() => {

    document.title = 'Mournify | Dashboard';
    fetchStats()
    fetchChartData()
    // window.location.reload()
    // setStatistics(stats)
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12">
            <h2 className="mainTitle">
               Dashboard
              </h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
                <div className="row ">                  
                  <div className="col-xxl-4 col-xl-5 col-md-6">
                    <div className="dashCard dashboard-stats">
                        <StatCard increase={true} image={stats[0].image} text={'TOTAL USERS'} text2={'Since Last Month'} total={statistics.total_users} trends={statistics.user_trend} />
                    </div>
                  </div> 
                  <div className="col-xxl-4 col-xl-5 col-md-6">
                    <div className="dashCard dashboard-stats">
                        <StatCard increase={true} image={stats[1].image} text={'TOTAL POSTS'} text2={'Since Last Month'} total={statistics.total_posts} trends={statistics.post_trend} />
                    </div>
                  </div>                  
                </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="dashCard">
                <div className="row">
                  <div className="col-12 d-sm-flex justify-content-between mb-3">
                  <h3 className="mainTitle">Post Analysis</h3>
                  <select className="dropdown-graph" onChange={handleChange} value={selectedValue}>
                      <option value="M">Monthly</option>
                      <option value="Q">Quarterly</option>
                      <option value="Y">Yearly</option>
                  </select>
                  </div>
                </div>
                <div className="graph-wrapper position-relative">
                  <CChart
                    type="line"
                    height="90"
                    options={{
                      scales: {
                        y: {
                          suggestedMin: 0,
                          suggestedMax: 40,
                        },
                      },
                    }}
                    data={{
                      labels: months,
                      tension: "0.5",
                      datasets: [
                        {
                          label: "Posts",
                          fill: false,
                          backgroundColor: "#2d73e4",
                          borderColor: "#2d73e4",
                          pointBackgroundColor: "#fff",
                          pointBorderColor: "#2d73e4",
                          borderWidth: 1,
                          data: records,
                          tension: 0.5,
                        },                        
                      ],
                    }}
                  />
                  <h6 className="text-center text-color fw-bold">Month</h6>
                  
                </div>
              </div>
            </div>
          </div>
          <div>            
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
