import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import "./style.css";

import { AuthLayout } from '../../Layout/AuthLayout';
import CustomButton from '../../Components/CustomButton';
import CustomInput from "../../Components/CustomInput"
import axios from "axios";

const AdminLogin = () => {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({})

    useEffect(() => {
        document.title = 'Mounrify  | Login';
    }, [])

    const handleClick = async (e) => {
        e.preventDefault()

        let response = await axios.post("admin/login", formData).then((response)=>{

        // console.log(response.data.errors);
        // console.log(response.data.errors);
            // document.getElementById(
            //   "responseFromServer"
            // ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Success !</strong> Redirecting to Dashboard</div>`;
     
            // localStorage.setItem("_token", response.data.token);
            // localStorage.setItem("user", JSON.stringify(response.data.user));
            // axios.defaults.headers.common[
            //   "Authorization"
            // ] = `Bearer ${response.data.token}`;
            // setTimeout(() => {
              
            //   document.getElementById("responseFromServer").hidden = true;
            //   navigate("/dashboard");
            // }, 500);
            if(response.data.errors){
                 document.getElementById(
              "responseFromServer"
            ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${response.data.message}</div>`;
      
            setTimeout(() => {
              document.getElementById("responseFromServer").children[0].hidden = true;
            }, 1500);

            }
            else{
               document.getElementById(
              "responseFromServer"
            ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Success !</strong> Redirecting to Dashboard</div>`;
     
            localStorage.setItem("_token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.token}`;
            setTimeout(() => {
              
              document.getElementById("responseFromServer").hidden = true;
              // navigate("/dashboard");
              window.location.reload();
            }, 500);
            }

        })
        .catch((e) => {
            document.getElementById(
              "responseFromServer"
            ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.message}</div>`;
      
            setTimeout(() => {
              document.getElementById("responseFromServer").children[0].hidden = true;
            }, 1500);
          });
    }

    return (
        <>
            <AuthLayout authTitle='Login' authPara='Login into your Account'>
            <div id="responseFromServer"></div>
                <form onSubmit={handleClick}>
                <CustomInput
                  label="Email Address"
                  required
                  id="userEmail"
                  type="email"
                  placeholder="Enter Your Email Address"
                  labelClass="mainLabel"
                  inputClass="mainInput"
                  onChange={(event) => {
                    setFormData({ ...formData, email: event.target.value });
                  }}
             />
          <CustomInput
            label="Password"
            required
            id="pass"
            type="password"
            placeholder="Enter Password"
            labelClass="mainLabel"
            inputClass="mainInput"
            onChange={(event) => {
              setFormData({ ...formData, password: event.currentTarget.value });
            }}
          />
                    <div className="d-flex align-items-baseline justify-content-between mt-1">
                        <div className="checkBox">
                            <input type="checkbox" name="rememberMe" id="rememberMe" className='me-1' />
                            <label htmlFor="rememberMe" className='fw-semibold'>Remember Me</label>
                        </div>
                        <Link to={'/forget-password'} className='text-dark text-decoration-underline'>Forgot Password?</Link>
                    </div>
                    <div className="mt-4 text-center">
                        <CustomButton variant='primaryButton' text='Login' type="submit" />
                    </div>
                </form>
            </AuthLayout>
        </>
    )
}


export default AdminLogin
