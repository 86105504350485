import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";


import { DashboardLayout } from "../../Layout/DashboardLayout";
import CustomTable from "./../../Components/CustomTable";

import UseTableControls from "../../Config/UseTableControls";

import { feedbacks } from "./../../Config/Data";

import "./style.css";

import axios from "axios";

export const Feedbacks = () => {

  const {
    filterSort,
    filterSortValue,
    setFilterSortValue,
    paginateFilter,
    setPaginateFilter,
    filterSortValues,
    filterSearch,
    filterSearchValue,
    setFilterSearchValue,
    dateFilter,
    filterFrom,
    setFilterFrom,
    filterTo,
    setFilterTo
  } = UseTableControls();

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [feedbacksState, setFeedbacks] = useState([])

  const listData = async (page = 1, filter = null) => {
    setData([]);
    let pages = page;
    let response = await axios
      .get(`admin/feedbacks?page=${pages + filter}`)
      .catch((e) => {
        console.log(e.response.data.message);
      });
    if (response.status) {
      let filter = response.data;
      window.e = filter;
      setData(response.data.data);
      setPaginateFilter(filter.meta);
    }
  };

  const searchFilter = () => {
    let search = `&search=${filterSearchValue}`;
    let queryParams = `&from=${filterFrom}&to=${filterTo}&sort=${filterSortValue}`;

    listData(undefined, search + queryParams);
  };

  const pageChange = (value = 1) => {
    listData(value);
  }

  useEffect(() => {
    document.title = 'Mournify| Feedbacks';
    listData();
  }, []);

  const feedbackHeaders = [
    {
      key: "id",
      title: "S.No",
    },
    {
      key: "name",
      title: "Full Name",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "date",
      title: "Date",
    },
    {
      key: "actions",
      title: "Actions",
    },

  ];

  const sortingValues = [
    // {
    //   value: 'all',
    //   text: 'All'
    // },
    // {
    //   value: 'status',
    //   text: 'Status'
    // },
    // {
    //   value: 'created_at',
    //   text: 'Date'
    // },
  ]



  return (
    <>
      <DashboardLayout>
        <div className="container-fluid">
          <div className="row align-items-baseline my-4">
            <div className="col-12 ">
              <h2 className="mainTitle"> Feedback Management</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <div className="dashCard">
                <div className="row mb-3">
                  <div className="col-12">
                    <CustomTable
                      headers={feedbackHeaders}
                      filterSort={true}
                      filterSortValue={filterSortValue}
                      setFilterSortValue={setFilterSortValue}
                      filterSortValues={sortingValues}
                      filterSearch={true}
                      filterSearchValue={filterSearchValue}
                      setFilterSearchValue={setFilterSearchValue}
                      searchFilter={searchFilter}
                      setPaginateFilter={setPaginateFilter}
                      paginateFilter={paginateFilter}
                      dateFilter={true}
                      filterFrom={filterFrom}
                      setFilterFrom={setFilterFrom}
                      filterTo={filterTo}
                      setFilterTo={setFilterTo}
                      pageChange={pageChange}
                      listData={listData}
                    >
                      <tbody>

                        {data.length > 0 ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.created_at}</td>
                              <td>
                                <Link to={`/feedbacks/feedback-details/${item.id}`} className="tableAction">View</Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No records found!</td>
                          </tr>
                        )}
                      </tbody>
                    </CustomTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
