import { useState, useEffect } from "react";

import { DashboardLayout } from "../../Layout/DashboardLayout";


import { Link } from "react-router-dom";
import "./style.css";

const Profile = () => {
 

  const [userData, setUserData] = useState({});

  useEffect(() => {
    document.title = "Mournify | My Profile";

    let user = JSON.parse(localStorage.getItem("user"));
    setUserData(user);
  }, []);

  return (
    <>
      <DashboardLayout>
      <div className="row my-4">
            <div className="col-12">
              <h2 className="mainTitle">My Profile</h2>
            </div>
          </div>
        <div className="dashCard mb-4">
          <div className="row">
            {userData ? (
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8 col-xl-8 col-xxl-6">
                    <div className="col-12">
                      <div className="profileImage mb-3">
                        <img src={userData.avatar_url ?? userData.avatar_url} alt="User" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <h4 className="primaryColor"> First Name </h4>
                        <p className="secondaryText">{userData.name}</p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <h4 className="primaryColor"> Last Name </h4>
                        <p className="secondaryText">{userData.last_name}</p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <h4 className="primaryColor"> Email Address </h4>
                        <p className="secondaryText">{userData.email}</p>
                      </div>
                    </div>
                    <div className="col-12">
                   <div className="mb-3">
                   <Link
                        type="button"
                        className="customButton primaryButton text-decoration-none edit-btn"
                        to="/profile/edit-profile"
                      >
                          Edit Profile
                        </Link>
                   </div>
                    </div>
                    <div className="col-12">
                      <Link
                        type="button"
                        className="mb-2 me-3 password-btn primaryColor notButton"
                        to="/profile/change-password"
                      >
                        Change Password
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Profile;
