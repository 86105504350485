import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";




import { DashboardLayout } from "../../Layout/DashboardLayout";
import BackButton from "../../Components/BackButton";
import CustomModal from "../../Components/CustomModal";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart , faComment , faShareSquare } from "@fortawesome/free-regular-svg-icons";
import LikeModel from "../../Components/LikeModel";
import CommentsModel from "../../Components/CommentsModel";

import axios from "axios";
import { Dropdown, DropdownButton } from "react-bootstrap";

const UserDetails = () => {

  const { id } = useParams();
  const navigate = useNavigate()
  // const [profileData, setProfileData] = useState({});
  const [postData, setPostData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);

  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false); 
  
  const [comments, setComments] = useState([]);
  const [postId, setPostId] = useState();
  const [likePostId, setLikePostId] = useState();
  const handleCloseComments = () => setShow2(false);
  const handleClose = (id) =>{
    // console.log(id);
    setLikePostId(id)
    setShow(false);

  };
  const handleShow = (id) => {
    setLikePostId(id)
    setShow(true)
  };
  const handleShowComments = (id) => {
    setPostId(id)
    setShow2(true)
  };

  

  const inactiveMale = () => {
    setShowModal(false);
    updateStatus();
    setShowModal4(true);
  };

  const activeMale = () => {
    setShowModal3(false);
    updateStatus();
    setShowModal2(true);
  };

 



  const updateStatus = async () => {
    let response = await axios
      .post(`admin/user/${id}`)
      .catch((e) => {
        // console.log(e.response.data.message);
      });
    if (response.status) {
      // console.log(response.data.data)
      this.fetchData();      
    }
  };
  
  useEffect(() => {
    document.title = 'Mournify | Details';
    
    const fetchData = async () => {
      let response = await axios
        .get(`admin/user/${id}`)
        .catch((e) => {
          console.log(e.response.data);
        });
      if (response.status) {
        console.log(response.data.data)
        console.log(response.data.data.post)
        setData(response.data.data);
        setPostData(response.data.data.post)
      }
     
    };

    
    
    fetchData();    
    
  }, [])
  return (
    <>
      <DashboardLayout>
      <div className="row my-4">
            <div className="col-12">
              <h2 className="mainTitle">
                <BackButton />
                User Details
              </h2>
            </div>
          </div>
          <div className="dashCard mb-4">
          <div className="row mb-3">
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-lg-4 ">
                  <div className="profileImage">
                    <img src={data.avatar_url} alt="User" />
                  </div>
                </div>
                <div className="col-lg-8 text-end">
                  {data.status === 1 ? (
                    <div className="d-flex align-items-center justify-content-end dropdown-wrapper">
                      <span>status :</span>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={`Active`}
                        className="active-dropdown"
                        variant="none"
                      >
                        <Dropdown.Item
                          href="#/action-1"
                          onClick={() => {
                            setShowModal3(true);
                          }}
                        >
                          Inactive
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-end dropdown-wrapper">
                      <span >Status :</span>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={`Inactive`}
                        className="inactive-dropdown"
                        variant="none"
                      >
                        <Dropdown.Item
                          href="#/action-1"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Active
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  )}
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-12 col-md-8 col-xl-8 col-xxl-6">
                  <div className="row">
                    <div className="col-lg-6 col-sm-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-2">
                            <h6 className="primaryColor fw-bold">
                              Full Name{" "}
                            </h6>
                            <p className="secondaryText">
                              {data.name}
                            </p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-2">
                            <h6 className="primaryColor fw-bold ">
                              {" "}
                              Email Adress
                            </h6>
                            <p className="secondaryText">{data.email}</p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-2">
                            <h6 className="primaryColor fw-bold">
                              Phone Number{" "}
                            </h6>
                            <p className="secondaryText">{data.dialing_code} {data.phone}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-2">
                            <h6 className="primaryColor fw-bold">
                              Date Of Birth
                            </h6>
                            <p className="secondaryText">{data.dob}</p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-2">
                            <h6 className="primaryColor fw-bold ">Country</h6>
                            <p className="secondaryText">
                              {data.country}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" >
                    <div className="col-12">
                      <h3 className="mainTitle my-3">Post Logs</h3>
                    </div>
                    {Array.isArray(postData) && postData.length > 0 ? (
                      postData.map((item, index) => (
                        <div className="col-12" key={item.id}>
                          <div className="d-flex gap-3">
                            <div className="img-border">
                              <img src={data.avatar_url} alt="User" />
                            </div>
                            <div>
                              <h4 className="sub-title m-0">
                                {data.name}
                              </h4>
                              <p className="date-color">{item.human_readable_date}</p>
                            </div>
                          </div>
                          <div className="col-12 my-3">
                            <h6 className="phera-text-color">
                              Name: {data.name}
                            </h6>
                          </div>
                          <div className="col-12 mb-2">
                            <h6 className="phera-text-color">
                              Phone Number: {data.dialing_code} {data.phone}
                            </h6>
                          </div>
                          <div className="col-12 mb-2">
                            <h6 className="phera-text-color">
                              {item.content}
                            </h6>
                          </div>
                          <div className="col-12">
                            <div className="row mt-3 mt-lg-3 mt-xxl-3">
                              <div className="col-12 col-sm-4 ">
                                <p
                                  onClick={() =>handleShow(item.id)}
                                  className="date-color text-start text-sm-start like_btn"
                                >
                                  <FontAwesomeIcon icon={faHeart} />{" "}
                                  {item.allreactions_count} reactions
                                </p>
                              </div>
                              <div className="col-12 col-sm-4">
                                <p
                                   onClick={()=>handleShowComments(item.id)}
                                  className="date-color text-center text-start like_btn "
                                >
                                  <FontAwesomeIcon icon={faComment} />{" "}
                                  {item.comments_count} comments
                                </p>
                              </div>
                              <div className="col-12 col-sm-4 ">
                                <p className="date-color text-center text-sm-end">
                                  <FontAwesomeIcon icon={faShareSquare} />{" "}
                                  1 shares
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    
                      ) : (
                        <div className="col-12">
                          <p  className="text-center text-start">No records found!</p>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>       
        <CustomModal
          show={showModal}
          close={() => {
            setShowModal(false);
          }}
          action={inactiveMale}
          heading="Are you sure you want to mark this user as active?"
        />
        <CustomModal
          show={showModal4}
          close={() => {
            setShowModal4(false);
          }}
          successAction={() => {
            navigate("/user-management");
          }}
          success
          heading="Marked as Active"
        />
        <CustomModal
          show={showModal3}
          close={() => {
            setShowModal3(false);
          }}
          action={activeMale}
          heading="Are you sure you want to mark this user as Inactive?"
        />
        <CustomModal
          show={showModal2}
          close={() => {
            setShowModal2(false);
          }}
          successAction={() => {
            navigate("/user-management");
          }}
          success
          heading="Marked as InActive"
        />

         <LikeModel likePostId={likePostId} show={show} handleClose={handleClose}/>              
         <CommentsModel postId={postId} show={show2} handleCloseComments={handleCloseComments}/>
   
      </DashboardLayout>
    </>
    );
  };

export default UserDetails;
