
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { check, question } from '../../Assets/images';
import './style.css'
import { useState } from 'react';


const CustomModal = (props) => {

  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  }

 

  const handleSubmit = () => {
    if (props?.onSubmit) {
      props.onSubmit(reason);
    }
  }

  return (
    <>
      <Modal show={props?.show} centered onHide={props?.close}>
        <button className='closeButton' onClick={props?.close}><FontAwesomeIcon icon={faTimes} /></button>
        <Modal.Body className='text-center'>
          {props?.success ?
            <img src={check} alt="check" className='modalImage' />
            : props?.showReason? ""
            : <img src={question} alt="question" className='modalImage' /> 
          }
          <div className="modalContent">
            <h2 className={props.showReason ? 'reason-heading' : 'modalHeading'}>{props?.heading}</h2>
            {props?.showReason && 
              <div className="modalReason my-3">
                <textarea className="reasonTextarea" rows="4" cols="50" placeholder="Enter reason here" value={reason} onChange={handleReasonChange}></textarea>
              </div>
            }
            {props?.success ?
              <button type='button' onClick={props?.successAction} className='primaryButton modelbutton'>Ok</button>
              :
              props?.showReason? 
                <button type='button' onClick={props?.handleSubmit} className='primaryButton modelbutton me-2'>Submit</button>
              :
              <>
                <button type='button' onClick={props?.action} className='redbutton modelbutton me-2'>Yes</button>
                <button type='button' onClick={props?.close} className='greybutton modelbutton me-2'>No</button>
              </>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomModal;