import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



import { AuthLayout } from '../../Layout/AuthLayout';
import CustomInput from "../../Components/CustomInput"
import CustomButton from '../../Components/CustomButton';
import CustomModal from '../../Components/CustomModal';

import "./style.css";
import axios from "axios";

const ForgetPassword3 = (props) => {
    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({});
    var passwordSuccess = false;
    useEffect(() => {
        document.title = 'mounrify | Password Recovery';
    }, [])


    const handleClick = async (e) => {
        window.email = props
        e.preventDefault();
    
        if (formData.password != formData.password_confirmation) {
        document.getElementById(
            "responseFromServer"
        ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> Password not matched.</div>`;

        setTimeout(() => {
            document.getElementById("responseFromServer").children[0].hidden = true;
        }, 2000);
        } else {
        let data = formData;
        formData.email = props.email;
        data["email"] = props.email;
        let response = await axios.post("admin/set/password", formData).then((response) => {
        
            if(response.data.errors){
                document.getElementById(
                    "responseFromServer"
                    ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.msg}</div>`;
        
                    setTimeout(() => {
                    document.getElementById(
                        "responseFromServer"
                    ).children[0].hidden = true;
                    }, 2000);
                
            }
            else{
      
                document.getElementById(
                    "responseFromServer"
                    ).innerHTML = `<div class="alert alert-success"role="alert"><strong>Sucess !</strong> ${response.data.msg}</div>`;
                    passwordSuccess = true;
                    setTimeout(() => {
                    document.getElementById(
                        "responseFromServer"
                    ).children[0].hidden = true;
                    // props.email = null;
                    navigate("/login");
                    }, 2000);
            }
      
            
           
        }).catch((e) => {
         
           document.getElementById(
            "responseFromServer"
            ).innerHTML = `<div class="alert alert-danger"role="alert"><strong>Oops !</strong> ${e.response.data.msg}</div>`;

            setTimeout(() => {
            document.getElementById(
                "responseFromServer"
            ).children[0].hidden = true;
            }, 2000);
          });

        }
    }

    const modalAction = () => {
        setShowModal(false)
        navigate('/login')
    }

    return (
        <>
            <AuthLayout authTitle='Password Recovery' authPara='Type In Your New Password.' backOption={true}>
                <div id="responseFromServer"></div>
                <form onSubmit={handleClick}>
                    <CustomInput name="password" label='New Password' required id='pass' type='password' placeholder='Enter New Password' labelClass='mainLabel' inputClass='mainInput' onChange={(event) => {
                        setFormData({ ...formData, password: event.target.value })
                    }} />
                    <CustomInput name="password_confirmation" label='Confirm Password' required id='cPass' type='password' placeholder='Confirm Password' labelClass='mainLabel' inputClass='mainInput' onChange={(event) => {
                        setFormData({ ...formData, password_confirmation: event.target.value })
                    }} />

                    <div className="mt-4 text-center">
                        <CustomButton type='submit' variant='primaryButton' text='Update' />
                    </div>
                </form>
            </AuthLayout>
            <CustomModal show={showModal} close={() => { setShowModal(false) }} successAction={modalAction} success heading='Your password has been reset. Please login to continue.' />
        </>
    )
}



export default ForgetPassword3





