import React, { useEffect } from "react";
const TermsAndCondition = () => {
  useEffect(() => {
    document.title = "Mournify | Terms and Conditions";
  }, []);
  return (
    <>
      <div class="container">
        <div class="row py-3">
          <div class="col-12">
            <h2 class="text-center mainTitle pb-3">Terms and Conditions</h2>
            <p>
              These rules (the "Agreement") say how you can use the Mournify
              website and app (collectively called the "Services"), which are
              owned and run by Mournify ("Mournify," "we," or "us"). You agree
              to be bound by this Agreement when you access or use the Services.
              Please don't use the Services if you don't agree with this
              Agreement.
            </p>
            <h3 className="sub-title">1. How to Use Our Services:</h3>

            <p>
              <strong>1.1 Eligibility:</strong> To use the Services, you must be
              at least 18 years old and have the legal ability to enter into a
              binding contract.
            </p>
            <p>
              <strong>1.2 User Account:</strong> : You may need to make a user
              account to use certain parts of the Services. You agree to fill
              out your account with correct and complete information and to keep
              your account information up to date. You are responsible for
              keeping your account login information secret and for everything
              that happens while your account is being used. You agree to tell
              us right away if someone else uses your account or if there is
              another security breach.
            </p>
            <p>
              <strong>1.3. Prohibited Uses: </strong> You agree not to use the
              Services for any illegal or unauthorized purpose, such as
              spamming, phishing, or doing anything that violates the
              intellectual property rights of Mournify or any third party.
            </p>
            <h3 className="sub-title">2. Content:</h3>

            <p>
              <strong>2.1. User Content:</strong> The Services let you upload,
              send, store, send, or receive text, photos, videos, and audio,
              among other things (collectively, "User Content"). You own
              everything you upload, submit, store, send, or receive through the
              Services, and you are the only one who is responsible for it.
            </p>
            <p>
              <strong>2.2. Content owned or licensed by Mournify: </strong>The
              Services may have text, graphics, photos, videos, and software,
              among other things, that are owned or licensed by Mournify
              (collectively, "Mournify Content"). Copyright, trademark, patent,
              trade secret, and other laws protect Mournify content, so it can't
              be copied, changed, reproduced, or shared in any way without our
              written permission first.
            </p>
            <p>
              <strong>2.3. License to User Content: </strong>If you upload,
              submit, store, send, or receive User Content through the Services,
              you give Mournify a non-exclusive, transferable, sub-licensable,
              royalty-free, worldwide license to use, copy, modify, create
              derivative works based on, distribute, publicly display, publicly
              perform, and otherwise use such User Content in any way (including
              in connection with the Services).
            </p>
            <p>
              <strong>2.4. Content that isn't allowed: </strong> You agree not
              to upload, submit, store, send, or receive through the Services
              any content that is illegal, obscene, harmful, threatening,
              abusive, harassing, defamatory, vulgar, offensive, or otherwise
              objectionable.
            </p>

            <p>
              Payment: Mournify may charge a fee for some services or features.
              You agree to pay all fees and charges related to your use of the
              Services. This includes, but is not limited to, subscription fees,
              transaction fees, and other charges. You agree to give correct and
              complete billing information, such as your billing address and how
              you want to pay.
            </p>
            <p>
              Termination: Mournify can end your access to all or part of the
              Services at any time, with or without a reason, with or without
              notice, and it will take effect right away. As soon as this
              Agreement ends, you will no longer be able to use the Services.
            </p>

            <h3 className="sub-title">5. Disclaimers:</h3>
            <p>
              <strong>5.1. No Guarantee: </strong>The Services are offered "as
              is" and "as available." Mournify doesn't make any promises or
              warranties, either express or implied, about how the Services work
              or about the information, content, materials, or products that are
              part of or available through the Services. You agree in no
              uncertain terms that you use the Services at your own risk.
            </p>
            <p>
              <strong>5.2. Limitation of Liability </strong>: To the fullest
              extent allowed by applicable law, Mournify shall not be liable for
              any indirect, incidental, special, consequential, or punitive
              damages arising out of or related to this Agreement, including but
              not limited to loss of profits, loss of data, loss of goodwill, or
              other intangible losses. Mournify's total liability for claims
              related to or arising from this Agreement will never be more than
              the amount you paid Mournify for using the Services.
            </p>
            <p>
              Third-Party Services: The Services may include links to
              third-party websites, services, or other resources that Mournify
              does not own or control. Mournify doesn't support or take
              responsibility for these sites, information, materials, products,
              or services from third parties. You agree that Mournify is not
              responsible or liable for the content or accuracy of any of these
              third-party sites or services.
            </p>
            <h3 className="sub-title">6. Indemnification:</h3>
            <p>
              You agree to protect, defend, and hold harmless Mournify, its
              affiliates, officers, directors, employees, agents, licensors, and
              suppliers from and against any claims, actions, suits, or
              proceedings, as well as any and all losses, liabilities, damages,
              costs, and expenses (including reasonable attorneys' fees) arising
              out of or related to your use of the Services or any violation of
              this Agreement.
            </p>
            <h3 className="sub-title">7. Governing Law:</h3>
            <p>
              This Agreement will be governed by and interpreted according to
              the laws of the State of California. No conflict of law rules will
              apply.
            </p>
            <h3 className="sub-title">8.Resolution of Disputes:</h3>
            <p>
              Any disagreement about this Agreement or the Services must be
              settled by binding arbitration in accordance with the Commercial
              Arbitration Rules of the American Arbitration Association. The
              arbitration will take place in San Francisco, California, and any
              court with the right to do so can make a decision based on the
              arbitrators' decision.
            </p>
            <h3 className="sub-title">9.Changes to the Agreement:</h3>
            <p>
              Mournify can change this Agreement at any time by posting the new
              terms on the Services. If you keep using the Services after the
              new terms are posted, that means you agree to the changes.
            </p>
            <h3 className="sub-title">10.End of the Agreement:</h3>
            <p>
              This is the end of the
              agreement between you and Mournify about how to use the Services.
              It replaces all previous agreements and understandings, whether
              they were written or spoken.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndCondition;
