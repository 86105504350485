import ForgetPassword3 from "../Screens/Auth/ForgetPassword3";
import { connect } from "react-redux";
import emailTraveling from "../Services/Actions/action";

const mapStateToProps = (state) => state.emailTraveling;
const mapDispatchToProps = (dispatch) => ({
  emailHandler: (data) => dispatch(emailTraveling(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword3);
