import CustomFilters from "../CustomFilters";
import CustomPagination from "../CustomPagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisV, faEye, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const CustomTable = (props) => {
  const searchHere = () => {
    props?.searchFilter();
  }
  return (
    <>
    <div className="filterWrapper justify-content-end d-flex gap-3">
    <CustomFilters
        filterSort={props?.filterSort}
        filterSortValue={props?.filterSortValue}
        setFilterSortValue={props?.setFilterSortValue}
        filterSortValues={props?.filterSortValues}

        filterSearch={props?.filterSearch}
        filterSearchValue={props?.filterSearchValue}
        setFilterSearchValue={props?.setFilterSearchValue}

        dateFilter={props?.dateFilter}
        filterFrom={props?.filterFrom}
        setFilterFrom={props?.setFilterFrom}
        filterTo={props?.filterTo}
        setFilterTo={props?.setFilterTo}
        searchFilter={props?.searchFilter}
        listData={props?.listData}
      />
        <div className="searchWrapper">
          <input
            type="text"
            placeholder="Search"
            name="search"
            className="filterInput searchInput"
            value={props?.filterSearchValue}
                    onChange={(event) => {
                      props?.setFilterSearchValue(event.target.value);
                    }}
          />
          <button className="searchButton notButton" onClick={() => {
                  searchHere();
                }}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
    </div>
      <div className="customTable">
        <table>
          <thead>
            <tr>
              {props?.headers.map((header) => (
                <th key={header.key}>{header.title}</th>
              ))}
            </tr>
          </thead>
          {props?.children}
        </table>
      </div>
      <CustomPagination 
        paginateFilter={props?.paginateFilter} 
        pageChange = {props?.pageChange}
      />
    </>
  );
};

export default CustomTable;
