import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import AdminLogin from "../Screens/Auth/Login";
import ForgetPassword from "../Containers/ForgetPasswordContainer";
import ForgetPassword2 from "../Containers/ForgetPassword2Container";
import ForgetPassword3 from "../Containers/ForgetPassword3Container";

import AuthGuard from "../Config/AuthGuard";

import { Dashboard } from "../Screens/Dashboard";
import Notifications from "../Screens/Notifications";

import UserManagement from "../Screens/UserManagement"
import UserDetails from "../Screens/UserManagement/UserDetails";

import { Feedbacks } from "../Screens/Feedbacks";
import FeedbackDetails from "../Screens/Feedbacks/FeedbackDetails";

import Profile from "../Screens/Profile";
import EditProfile from "../Screens/Profile/EditProfile";
import ChangePassword from "../Screens/Profile/ChangePassword";
import PrivacyAndPolicy from "../Screens/Public/PrivacyPolicy";
import TermsAndCondition from "../Screens/Public/TermsAndCondition";
import ContactUs from "../Screens/Public/ContactUs";

export default function AdminRouter() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        
        <Route path="/" element={<Navigate to="/login" />}  />
        <Route path="/login" element={<AuthGuard Component={AdminLogin} />} />
        <Route path="/forget-password" element={<AuthGuard Component={ForgetPassword} />} />
        <Route path="/forget-password2" element={<AuthGuard Component={ForgetPassword2} />} />
        <Route path="/forget-password3" element={<AuthGuard Component={ForgetPassword3} />} />

        <Route path="/dashboard" element={<AuthGuard Component={Dashboard} />} />
        <Route path="/notifications" element={<AuthGuard Component={Notifications} />} />

        <Route path="/user-management" element={<AuthGuard Component={UserManagement} />} />
        <Route path="/user-management/details/:id" element={<AuthGuard Component={UserDetails} />} />

        <Route path="/feedbacks" element={<AuthGuard Component={Feedbacks} />} />
        <Route path="/feedbacks/feedback-details/:id" element={<AuthGuard Component={FeedbackDetails} />} />

        <Route path="/profile" element={<AuthGuard Component={Profile} />} />
        <Route path="/profile/edit-profile" element={<AuthGuard Component={EditProfile} />} />
        <Route path="/profile/change-password" element={<AuthGuard Component={ChangePassword} />} />

        <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/contact-us" element={<ContactUs />} />

      </Routes>
    </BrowserRouter>
  );
}
