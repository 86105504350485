
import "bootstrap/dist/css/bootstrap.css"
import AdminRouter from './Routers';
import "./Assets/css/style.css";
import { NotificationProvider } from "./Layout/Header/NotificationContext";
function App() {
  return (
    <NotificationProvider>
    <AdminRouter />
    </NotificationProvider>
  );
}
export default App;
