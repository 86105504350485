
import { useState, useEffect } from "react";

import { DashboardLayout } from '../../Layout/DashboardLayout';
import { notifications } from '../../Config/Data';

import NotificationCard from "../../Components/NotificationCard";
import axios from "axios";
import './style.css'
import CustomButton from "../../Components/CustomButton";
import { useNotificationContext } from "../../Layout/Header/NotificationContext";

const Notifications = () => {
  
  const { notificationCount } = useNotificationContext(); // Access context values

  // console.log(notificationCount);
  const [notificationState, setNotificationState] = useState([])
  const [notificationType, setNotificationType] = useState('all')
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const fetchNotifications = async () => {
    let response = await axios
      .get(`admin/notification/all/list`)
      .catch((e) => {
        console.log(e.response.data.message);
      });
    if (response.status) {
      console.log(response.data.data)
      setNotificationState(response.data.data)
      // setNotificationState(response.data.data);
    }
  };

  useEffect(() => {

    document.title = 'Mournify | Notifications';
    
    fetchNotifications();
    // console.log('notifications:'+notificationCount);
  }, [])

  const displayNotifications = () => {
    if (showAllNotifications) {
      return notificationState;
    } else {
      return notificationState.slice(0, 4);
    }
  }

  return (
    <div>
      <DashboardLayout>
          <div className="row my-4">
              <div className="col-12">
                <h2 className="mainTitle">Notifications</h2>
              </div>
            </div>
        <div className="dashCard">
          <div className="container-fluid">
            <div className="row">
              {displayNotifications().map((notification, index) => (
                <div className="col-12" key={index}>
                  <NotificationCard  notificationCount={notificationCount} id={notification.id} text={notification.message} date={notification.created_at} time={notification.time} title={notification.title} read={notification.read} image={notification.image}/>
                </div>
              ))}
            </div>
            {notificationState.length > 4 && (
              <div className="row mt-2">
                <div className="col-12 text-center">
                  <CustomButton onClick={() => setShowAllNotifications(!showAllNotifications)} variant={'primaryButton'} text={showAllNotifications ? 'Show Less' : 'View All'} />
                </div>  
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </div>
  )
}

export default Notifications;

